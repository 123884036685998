import React from "react";
import styled from "styled-components";

const CardWrap = styled.div`
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 16px;
  overflow: hidden;
  border: solid 1px #dddddd;
`;

const Overtitle = styled.div`
  position: absolute;
  margin: 16px;
  color: white;
  text-shadow: 1px 1px 5px #00000070;
  font-size: x-large;
  font-family: "TAEBAEKfont";
`;

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Subpa = (props) => {
  return (
    <CardWrap>
      <Overtitle>{props.title}</Overtitle>
      <CardImg src={props.url} />
    </CardWrap>
  );
};

export default Subpa;
