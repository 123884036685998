import styled from "styled-components";

const SpaceBox = styled.div`
  height: 120px;
  display: block;
`;

const FooterBody = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-left: 16px;
  margin-right: 16px;
`;

const FooterContainer = styled.div`
  width: 100%;
  border-top: solid 1px rgb(150, 150, 150);
`;

const Footer = () => {
  return (
    <FooterBody>
      <FooterContainer>
        <h4>Footer</h4>
        <SpaceBox />
      </FooterContainer>
    </FooterBody>
  );
};

export default Footer;
