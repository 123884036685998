import React from "react";
import styled from "styled-components";

const EventWrap = styled.div`
  overflow: hidden;
  max-width: 100%;
  border-radius: 16px;
  background: linear-gradient(
    322deg,
    rgba(237, 117, 255, 1) 0%,
    rgba(147, 122, 255, 1) 100%
  );
  color: white;
  margin-left: 16px;
  margin-right: 16px;
`;

const TextBody = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  animation: infinite linear;
  animation-duration: ${(props) => props.speed}s;
  animation-name: scrollText;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
  h3 {
    margin-left: 1em;
  }

  &:hover {
    animation-play-state: paused;
  }
  @keyframes scrollText {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const eventText = "진행중인 이벤트 없음.. 방장님이 잘 만들길 응원하는 수밖에..";

const notice = [
  {
    id: 1,
    text: eventText,
  },
  {
    id: 2,
    text: eventText,
  },
  {
    id: 3,
    text: eventText,
  },
  {
    id: 4,
    text: eventText,
  },
  {
    id: 5,
    text: eventText,
  },
  {
    id: 6,
    text: eventText,
  },
];

const Event = () => {
  const speed = eventText.length / 2;
  return (
    <EventWrap>
      <TextBody speed={speed}>
        {notice.map((e) => (
          <h3 key={e.id}>{e.text}</h3>
        ))}
      </TextBody>
    </EventWrap>
  );
};

export default Event;
