import { Outlet, createBrowserRouter } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import Write from "./Write";
import Single from "./Single";
import NotFound from "./NotFound";
import TabBar from "./TabBar";
import Details from "./Details";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <TabBar />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/post/:id",
        element: <Single />,
      },
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/Write",
    element: <Write />,
  },
  {
    path: "/Details",
    element: <Details />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

export default router;
