import styled from "styled-components";
import { Link } from "react-router-dom";

const MainFlex = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: flex;
  -webkit-box-pack: center;
  flex-direction: column;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @font-face {
    font-family: "SANJUGotgam";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2112@1.0/SANJUGotgam.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
  .notosanskr * {
    font-family: "Noto Sans KR", sans-serif;
  }
`;

const TextBox = styled.div`
  margin: 4px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media all and (max-width: 650px) {
    margin: 4px 4px;
  }
  color: #62504c;

  section {
    background-color: rgba(250, 250, 250, 0.8);
    border-radius: 16px;
    padding: 2px 16px;
    &:hover {
      background-color: rgba(250, 250, 250, 0.8);
    }
  }

  h3 {
    font-family: "Noto Sans KR", sans-serif;
  }

  p {
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1.7em;
  }

  li {
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1.7em;
  }
  h6 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 0.7em;
    font-weight: 400;
  }
`;

const HeadTitle = styled.div`
  /* position: sticky;
  top: -1px; */
  margin-top: 32px;
  overflow-wrap: break-word;
  padding: 12px;
  font-family: "SANJUGotgam";
  background: linear-gradient(
    343deg,
    rgb(255 120 177) 0%,
    rgb(255 191 138) 100%
  );
  color: white;
  border-radius: 16px;

  h1 {
    font-size: 5.5em;
  }

  @media all and (max-width: 650px) {
    margin-top: 0;
    border-radius: 0;
    padding: 0 16px;
    h1 {
      margin-block-start: 16px;
      font-size: 5.5em;
    }
  }
`;

const BtnRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-top: 23px;
  margin-bottom: 24px;
`;

const BtnFlex = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
`;

const Btn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 65px;
  margin-top: 8px;
  border-radius: 16px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  font-family: "Noto Sans KR", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  &:hover {
    animation: gelatine 0.3s;
  }
  @keyframes gelatine {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
`;

const Details = () => {
  const green = "rgb(122, 204, 82);";
  const blue = "rgb(82, 198, 204);";
  const pink = "#ffa2b0";
  const yellow = "rgb(252, 245, 98);";
  const black = "rgb(0,0,0);";
  const white = "rgb(255,255,255);";

  return (
    <div
      style={{
        background: "rgb(254,204,203)",
        width: "100%",
      }}
    >
      <MainFlex>
        <HeadTitle>
          <h1>세부규정</h1>
          <h2 style={{ textAlign: "right" }}>Subpa!</h2>
        </HeadTitle>

        <TextBox>
          <section>
            <h2>목적</h2>
            <p>
              썹파는 그림그리는 사람들의 자유로운 소통을 위한 공간입니다. 그림에
              관심이 없거나, 예술관련 활동이 없는 사람은 이용이 불가합니다.
              썹파는 예술가를 지원하고 소통의 장을 제공하는데 그 목적이
              있습니다.
            </p>
          </section>
          <section>
            <h2>회원</h2>
            <p>
              회원은 관리자를 포함해 자격이 있는 모두를 뜻합니다. 활동 기간에
              상관없이 기본규정을 숙지하고, 자격증명을 완료한 모든 사람은
              회원자격을 가집니다.
            </p>
            <h3>회원자격</h3>
            <p>
              회원은 썹파의 모든 이벤트와 서비스를 이용할 수 있습니다. 디스코드,
              게임서버 등 온라인으로 연동되는 모든 채널은 썹파 오픈채팅방을
              중심으로 이뤄집니다.
            </p>
            <p>
              <span style={{ fontSize: "0.8em", color: "red" }}></span>
            </p>
            <h3>회원자격을 얻는법</h3>
            <p>
              그림에 대한 관심을 회원들에게 보여서, 진지하게 활동을 하고 있다는
              것을 증명해야 합니다. 장난식으로 생각하는 사람이나, 구경꾼은
              필요하지 않습니다.
              <br />
              나이는 현재 중학교 2학년 (14세 불가능) 이상만 참여 가능합니다.
            </p>
            <h3>그림증명</h3>
            <p>
              증명을 할 때에는 본인의 서명이 있는 최소 4장의 그림을 보여주셔야
              합니다. 그림을 “이제부터 잘 해보려고 마음먹은 사람”은 아쉽지만
              당장 증명을 할 수 없기 때문에, 준비를 해주셔야 합니다.
            </p>
            <h3>증명기준</h3>
            <ul>
              <li>1년 안에 본인이 그렸던 최선의 그림일 것</li>
              <li>
                본인이 활동하는 동일한 닉네임이 써 있거나, 워터마크 또는 고유
                서명이 되어있을 것
              </li>
              <li>최소 4장부터 10장 까지 올릴것</li>
              <li>10분 안에 올릴것</li>
              <li>그림이 ¼ 초과로 가려져있지 않아야 할 것</li>
              <li>모작 가능</li>
              <li>흑백그림 가능</li>
              <li>크로키나 스케치단계의 그림 가능 (각각 서명이 있어야 함)</li>
              <li>
                본인의 그림을 스크린 캡쳐한 것 불가능 (손그림은 사진 가능)
              </li>
              <li>레이어인증 불필요</li>
              <li>
                성기, 유두가 노출된 그림은 부분 가림처리로 인증가능 (인체
                크로키에 유두 허용)
              </li>
              <li>본인의 그림이 게시된 링크나 SNS공유로 인증 불가능</li>
              <li>필터를 사용해 추가변형이 없는 그림 위주로 인증할 것</li>
            </ul>
            <h3>증명을 해야하는 이유</h3>
            <p>
              그림에 대해 소통하고 친목하는 것이 방의 목적인 만큼 그림에 대한
              열정을 확인 할 수 있어야 하고, 진지하게 그림을 생각하며 소통하는
              회원들의 명예를 존중해야 하고, 소통에 방해가 되는 사람을 걸러내기
              위함입니다.
            </p>
            <h3>4장 이상이여야 하는 이유</h3>
            <p>
              1장은 누구라도 금새 낙서처럼 그려오거나 복사해 올 수 있고, 1년간
              3장의 그림은 진지한 그림 활동을 한다고 보기 어려우며, 4장 부터는
              그림끼리 비교 판별이 용이하고 무단도용이나 파쿠르 등이 의심될 때
              온라인에서 검색할 수 있는 범위를 늘릴 수 있기 때문입니다. 당장
              준비되지 않은 사람들은 나가서 준비를 하고, 인증절차를 밟으시면
              됩니다.
            </p>
            <h3>나이 제한의 이유</h3>
            <p>
              소통의 질 향상을 위해 많은 제안 사항이 있었고, 80%의 동의로 이를
              시행함. 특별 예외조항으로, 초대한 회원이 책임지는 조건으로 14세
              이하 초대입장이 가능합니다. 만일 초대된 회원이 규칙을 잘 지키지
              못할 경우, 초대한 사람이 함께 불이익을 받습니다.
            </p>
            <h3>회원유지</h3>
            <p>
              썹파 회원으로 한번 인증되면, 그 후로 규정을 어기지 않는 한도내에서
              회원자격이 영원히 유지됩니다. 모든 회원은 재입장이 가능합니다.
            </p>
            <h3>재입장시 인증 건너뛰기 방법</h3>
            <p>
              인증절차를 건너 뛰고 싶다면, 퇴장을 하기 전 본인의 가장 최신
              그림을 올리고 본인의 이름을 태그(@본인닉네임) 한 후에 퇴장을 하고
              당일 포함 7일 이내 재입장 하여, 다른 회원에게 태그확인 요청을 하여
              인증을 건너뛸 수 있습니다.
              <br />
              7일이 지나거나, 그냥 나가게 된 경우엔 처음 증명과 같은 방법으로
              재진행합니다.
            </p>
          </section>
          <section>
            <h2>회원의 권리</h2>
            <p>썹파회원은 아래와 같은 권리를 가집니다.</p>
            <h3>1 신고</h3>
            <p>
              썹파 회원은 채팅방에서 일어나는 불의한 일에 한하여 관리자에게
              개인적으로 신고 할 수 있습니다.
            </p>
            <h3>2 소통</h3>
            <p>
              썹파 회원은 자유롭게 소통이 가능합니다. 회원이 아닌 사람과의
              불편사항은 현 톡방에서 해결 볼 수 없습니다. 영상, 음성, 여러형태의
              파일, 사진 등 자유롭게 게시 가능합니다. 서로 동의하에 하는 별도의
              개인 연락처 교환도 가능합니다. 화원끼리의 새로운 이벤트나 별도의
              합작 등을 규정내에서 하실 수 있습니다. 그림 소통방인 만큼, 그림이
              올라오면 최소 하트를 누르는 행위 등으로 반응하기를 권장드립니다.
              만일 반응이 자율적으로 잘 유지되지 않는다면, 반응을 강제하는 추가
              조항이 생길 수 있습니다.
            </p>
            <h3>3 참여</h3>
            <p>
              썹파 내에서 공식적으로 발생하는 이벤트에 자유롭게 참여가
              가능합니다. 또한 썹파 톡방이 아닌 다른 소통 채널에도 참여가
              가능합니다. <br />
              (예: 크로키방, 디스코드 등)
            </p>
            <h3>4 제재</h3>
            <p>
              규정을 잘 숙지하고 있는 회원은 잘 모르는 사람을 제재 할 수
              있습니다. 다만 집행이나 결정이 필요한 경우에는 관리자를 호출 할 수
              있습니다.
            </p>
            <h3>5 투표</h3>
            <p>
              모든 투표에 자유롭게 참여할 수 있습니다. 관리자의 허락을 받아
              투표를 생성할 수 있습니다.
            </p>
            <h3>6 저작</h3>
            <p>
              썹파내에서 활동으로 생긴 본인의 저작물은 회원 본인에게 저작권이
              있습니다. 합작이나 협업의 경우, 저작권 문제가 생기면 개인끼리 별도
              협의를 보셔야 합니다.
            </p>
          </section>
          <section>
            <h2>회원의 책임과 의무</h2>
            <p>썹파회원은 아래와 같은 권리를 가집니다.</p>
            <h3>1 답변</h3>
            <p>
              관리자가 요구하는 정보는 성실히 답변해야합니다. 만일 답변을
              성실하게 하지 않으면, 관리자 의결 없이 회원자격이 박탈 될 수
              있습니다. <br />
              (관리자는 절대로 회원님의 개인정보를 요구하지 않습니다)
            </p>
            <h3>2 이행</h3>
            <p>
              회원은 관리자가 요구하는 행위를 따를 의무가 있습니다. (회원끼리의
              다툼이 벌어져, 관리자가 정숙을 요구한 경우. 요구사항을 이행해야
              합니다.)
            </p>
            <h3>3 법적인 책임</h3>
            <p>
              썹파는 헌법과 민법 형법과 같은 상위 법을 우선으로 따릅니다. 회원은
              내부에서 상위 법으로 보호를 받는 동시에, 위법사항에 대하여 책임을
              다해야합니다. 저작권법에 위배되는 행위에 대해 썹파는 적극 협조할
              것을 약속합니다.
            </p>
            <h3>4 뉴비보호</h3>
            <p>
              썹파 회원은 신규회원을 소중히 대해야 합니다. 당신도 뉴비였던
              시절이 있습니다.
            </p>
            <h3>5 자료</h3>
            <p>
              회원님이 올리는 모든 자료는 자유롭게 삭제하거나, 상호허락 하에
              소지도 가능합니다. 하지만 상호 허락된 용도 외에 협의 되지 않은
              행위를 해서는 안됩니다. 유사행위라도 적발시 강퇴나 고소를 당할 수
              있습니다. <br />
              (예: 무단으로 썹파 내 내용을 캡쳐 후 외부 SNS에 게시 → 고소가능)
            </p>
          </section>
          <section>
            <h2>소통</h2>
            <h4>글/ 아래와 같이 제한합니다.</h4>
            <ul>
              <li>
                타인의 나이, 본명, 성별, 주소, 가족관계 등 특정할 수 있는 단서나
                개인정보를 언급, 게시하지 않습니다.
              </li>
              <li>일부의 비속어가 가능합니다.</li>
              <li>비방이나 욕설은 불가능합니다.</li>
              <li>
                인신공격으로 보이는 언행은 활동 제한 등 불이익이 있을 수 있고,
              </li>
              <li>
                피해자의 고소나 신고에 대해서 썹파에서 적극 협조할 것입니다.
              </li>
              <li>허락없는 피드백은 갈등을 유발합니다.</li>
              <li>
                상대의 발전을 생각해 피드백을 하고싶다면, 조심스럽게 허락을
                구해야합니다.
              </li>
              <li>예술관련 외 정치적인 발언은 일절 금지합니다.</li>
              <li>정치적 올바름을 남에게 강요하지 않습니다.</li>
              <li>종교적 발언은 작품 관련된 설명만 가능합니다.</li>
              <li>그 외 차별을 부추기는 발언을 금지합니다.</li>
              <li>잔인하고 선정적인 표현은 작품관련외 불가합니다.</li>
              <li>빻은 장르라며 남의 소통을 규제하거나 방해하지 않습니다.</li>
              <li>관심과 대답을 구걸하는 행위는 불가합니다.</li>
              <li>혐오 발언이나 선언은 불가합니다.</li>
            </ul>
            <br />
            <h4>이하는 경고없이 강퇴합니다.</h4>

            <ul>
              <li>파생 채팅방을 제외한 타 채팅방의 링크 게시</li>
              <li>똑같은 링크를 3회 이상 도배</li>
              <li>부계와 본계를 이용한 사칭이나 홍보</li>
              <li>이모티콘 도배</li>
              <li>
                비속어/욕설 ( 예 : 운지해라, 재기해라, 씹치남, 맘충, ~충, 씨발년
                등 )
              </li>
              <li>
                잔인한 표현 ( 예 : 창자가 터져나와 바퀴에 밟혀서 피가 터졌다 )
              </li>
              <li>
                저속한 표현 ( 예 : 빤스를 벗고 성기에 손가락을 넣고 흔들었다 )
              </li>
            </ul>
            <br />
            <h4>자료/ 아래와 같이 제한합니다.</h4>
            <p>
              SafeNet 자료* 를 기준으로 15세까지 가능하나, 살인장면은 안됩니다.
            </p>
            <p>- 노출 2 / 성행위 2 / 폭력 2 / 언어 2 - </p>

            <h4>불가능</h4>
            <ul>
              <li>절단된 신체가 보이는 자료</li>
              <li>피가 많이 보이는 자료</li>
              <li>전신이 나체로 있고, 중요 부위만 가린 자료</li>
              <li>
                성기나 유두가 보이는 자료 ( 그림자나 실루엣으로 유추할 수 있는
                것 포함 )
              </li>
              <li>성행위를 하는 자료 ( 상동 )</li>
              <li>성적인 수치심을 유발하는 자료일체</li>
            </ul>
            <h4>가능</h4>
            <ul>
              <li>본인의 그림</li>
              <li>본인의 사진</li>
              <li>본인의 저작물 ( 음악, 소설, 움짤 등 )</li>
              <li>리퀘/커미션을 요청했던 다른 사람의 그림</li>
              <li>다른 사람이 본인에게 그려준 그림</li>
              <li>참조했던 이미지 링크</li>
              <li>참조했던 이미지 원본</li>
              <li>키스나 혀가 보이는 딥키스</li>
              <li>
                인체 공부 크로키 자료에 한하여, 유두가 있는 자료/묘사 가능
              </li>
              <li>모자이크로 절단 부위를 가린 것</li>
              <li>피 부분을 스티커로 가린 것</li>
              <li>유명한 밈이나 짤</li>
              <li>트위터 페이스북 등에서 캡쳐한 짤</li>
              <li>허가받은 프로젝트 홍보물</li>
              <li>허가받은 오픈채팅방</li>
              <li>허가받은 제휴사이트</li>
              <li>허가받은 커미션 홍보물</li>
            </ul>
          </section>
          <BtnRow>
            <BtnFlex>
              <Btn
                to="https://open.kakao.com/o/g5TiJB1f"
                bgColor={yellow}
                textColor={black}
              >
                썹파로 돌아가기
              </Btn>
            </BtnFlex>
          </BtnRow>

          <h6 style={{ textAlign: "center", marginBottom: "60px" }}>
            이 앱/사이트에 올라오는 이미지는 SubPa 활동에 쓰여질 수 있으며, 모든
            개인 저작물은 법적인 보호를 받습니다. (법률 제17588호 저작권법,
            대법원)
            <br />
            SubPa는 원작자의 허락없는 저장, 캡쳐, 복사 등의 방법으로 소지하거나
            무단 복제 또는 트레이싱을 포함한 2차 창작을 금하고 있습니다.
            <br />
            또한 위와 더불어 출처를 명확하게 하지 않은 무단 공유나 링크 게제
            또한 법적인 처벌의 대상이 되거나, 민사소송의 사유가 될 수 있음을
            고지합니다. <br />
            <br />
            개발자/소유자 문의 :{" "}
            <a href="https://open.kakao.com/o/sHCkUfCb">
              https://open.kakao.com/o/sHCkUfCb
            </a>{" "}
            <br />
            <br />
            Copyright ⓒ 2021 SubPa. All Rights Reserved. <br />
            <br />
            CC BY NC ND
          </h6>
        </TextBox>
      </MainFlex>
    </div>
  );
};

export default Details;
