import { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as LogoSvg } from "../img/logo.svg";

const NavBody = styled.div`
  width: 100%;
  height: ${(props) => (props.isOver ? "40px" : "80px")};
  display: flex;
  position: fixed;
  top: -1px;
  z-index: 10;

  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);

  transition: height 0.5s cubic-bezier(0.28, 0.12, 0.15, 0.95);
`;

const Logo = styled.div`
  width: ${(props) => (props.isOver ? "80px" : "120px")};
  display: flex;
  margin-left: 12px;
  transition: width 0.5s cubic-bezier(0.28, 0.12, 0.15, 0.95);
`;

const NavWrap = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
`;

const ShadowBox = styled.div`
  width: 100%;
  height: 15px;
  overflow: hidden;
  position: fixed;
  top: ${(props) => (props.isOver ? "39px" : "79px")};
  z-index: 12;
  transition: top 0.5s cubic-bezier(0.28, 0.12, 0.15, 0.95);
`;

const Shadow = styled.div`
  display: ${(props) => (props.isOver ? "block" : "none")};
  width: 100%;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0px;
`;

const Navbar = () => {
  const [outTop, setOutTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 10) {
        setOutTop(true);
      } else {
        setOutTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <NavBody isOver={outTop}>
        <NavWrap>
          <Logo isOver={outTop}>
            <LogoSvg />
          </Logo>
        </NavWrap>
      </NavBody>

      <ShadowBox isOver={outTop}>
        <Shadow isOver={outTop} />
      </ShadowBox>
    </>
  );
};

export default Navbar;
