import styled from "styled-components";

const CardWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  border-radius: 16px;
  overflow: hidden;
  border: solid 1px #dddddd;
`;

const CardImg = styled.div`
  width: 100%;
  height: 70%;
  position: relative;
  background-color: ${(props) => props.color};
`;

const CardProfileWrap = styled.div`
  width: 100%;
  height: 30%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfileBox = styled.div`
  width: 40px;
  height: 40px;
  border: solid 1px #dddddd;
  border-radius: 10px;
  overflow: hidden;
  margin: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  max-width: 57%;
  overflow: hidden;
  h4,
  p {
    white-space: nowrap;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const Card = (props) => {
  const colorVal = props.color.substring(1);
  const url = `https://api.dicebear.com/8.x/adventurer/svg?seed=${props.seed}`;
  return (
    <CardWrap>
      <CardImg color={props.color} />
      <CardProfileWrap>
        <ProfileBox>
          <img src={url} alt="avatar" />
        </ProfileBox>
        <NameBox>
          <h4>{props.name}</h4>
          <p>{props.title}</p>
        </NameBox>
      </CardProfileWrap>
    </CardWrap>
  );
};

export default Card;
