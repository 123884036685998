import styled from "styled-components";

const TapBody = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  position: fixed;
  bottom: -1px;
  z-index: 100;

  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
`;

const TabBar = () => {
  return <TapBody>TabBar</TapBody>;
};

export default TabBar;
