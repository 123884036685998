import styled from "styled-components";

const NfWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    font-size: calc(0.7em + 1vw);
`


const NotFound = () => {
  return <div>NotFound</div>;
};

export default NotFound;
