import { RouterProvider } from "react-router-dom";
import router from "./components/Router";
import styled from "styled-components";

const ViewPort = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

function App() {
  return (
    <ViewPort>
      <RouterProvider router={router} />
    </ViewPort>
  );
}

export default App;
