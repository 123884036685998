import styled from "styled-components";
import Card from "./Card";
import Subpa from "./Subpa";
import Event from "./Event";

const SpaceBox = styled.div`
  height: 90px;
  display: block;
`;

const HomeBody = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;

  h1,
  p {
    margin-left: 16px;
  }
`;

const CardGrid = styled.div`
  display: grid;
  max-width: 600px;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 8px;
  margin-left: 16px;
  margin-right: 16px;
  @media (orientation: portrait) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const CardFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  /* width: 100%; */
  gap: 8px;
  margin-left: 16px;
  margin-right: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CardFlexShdow = styled.div`
  width: 15px;
  height: 100%;
  box-shadow: inset 1px 1px 5px 5px #000;
  position: absolute;
  right: 0;
`;

const CardFlexWrap = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  position: relative;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const color = [
  {
    id: 1,
    color: "#A7BED3",
    name: "⭐️ Li_Han",
    title: "사실 썹파 주인",
    seed: "Sophie",
  },
  {
    id: 2,
    color: "#C6E2E9",
    name: "⭐️ Sigae",
    title: "실세인척 함",
    seed: "Mimi",
  },
  {
    id: 3,
    color: "#F1FFC4",
    name: "⭐️ 갈롱",
    title: "어엿한 미대생",
    seed: "Sammy",
  },
  {
    id: 4,
    color: "#FFCAAF",
    name: "nipu",
    title: "커뮤니티 장",
    seed: "Snowball",
  },
  {
    id: 5,
    color: "#DAB894",
    name: "여울",
    title: "숨겨진 사장",
    seed: "Molly",
  },
];

const sub = [
  {
    id: 1,
    url: "https://picsum.photos/250/300",
    title: "자캐의 성별이 바뀌었다",
  },
  { id: 2, url: "https://picsum.photos/300/250", title: "자유주제" },
  {
    id: 3,
    url: "https://picsum.photos/200/250",
    title: "8월하면 떠오르는 것",
  },
  { id: 4, url: "https://picsum.photos/200/350", title: "병원에 간 포켓몬" },
  { id: 5, url: "https://picsum.photos/350/250", title: "뽀구리님이 헤응" },
];

const data = {
  id: 1,
  text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Libero justo laoreet
  sit amet cursus sit amet. Eget nunc scelerisque viverra mauris in
  aliquam. Hac habitasse platea dictumst quisque sagittis purus sit.
  Natoque penatibus et magnis dis parturient montes. Interdum velit
  euismod in `,
};

const Home = () => {
  return (
    <HomeBody>
      <SpaceBox />
      <Event />
      <h1>HOF</h1>
      <CardGrid>
        {color.map((e) => (
          <Card
            key={e.id}
            color={e.color}
            name={e.name}
            title={e.title}
            seed={e.seed}
          />
        ))}
      </CardGrid>
      <h1>Challenge</h1>
      <CardFlexWrap>
        {/* <CardFlexShdow /> */}
        <CardFlex>
          {sub.map((e) => (
            <Subpa key={e.id} title={e.title} url={e.url} />
          ))}
        </CardFlex>
      </CardFlexWrap>
      <p>{data.text}</p>
    </HomeBody>
  );
};

export default Home;
